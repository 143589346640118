/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;
@use './card';
@use './dialog';
@use './datetime-picker';
@use '@fp/common/src/styles';
@use './theme' as theme;

$fa-fort-path: "@fontawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";

@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@include theming.angular-material-theme(theme.$vni-app-theme);
@include theming.angular-material-typography(theme.$vni-typography);

body {
  margin: 0;
  background-color: #FFFFFF;
  font-family: mundial, sans-serif;
  font-weight: 300;
  overflow: hidden;
  color: mat.get-color-from-palette(theme.$vni-app-primary, 700);
  font-display: swap;
  line-height: 1.8;
}

h1, h2, h3, h4, h5 {
  line-height: 1;
}

mat-icon {
  letter-spacing: normal;
}

.mat-drawer-container {
  color: mat.get-color-from-palette(theme.$vni-app-primary, 700);
  background-color: #FFFFFF;
}

a {
  cursor: pointer;

  sup {
    display: inline-block;
    text-decoration: none;
  }
}

p:not(:last-child) {
  margin-bottom: 48px;
}

button.mat-raised-button[mat-raised-button] {
  font-weight: 600;
}

button {
  &.mat-raised-button,
  &.mat-button {
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 26px;
    color: theme.$vni-button-text-color;
    padding: 0 4em;
  }
}

a.mat-raised-button,
button.mat-raised-button {
  color: theme.$vni-button-text-color;
}

a.mat-button,
button.mat-button {
  color: theme.$vni-button-text-color;
}

strong {
  font-weight: 600;
}

h1 {
  font-weight: 400;
}

h2 {
  font-size: 24px;
  font-weight: 100;
  margin-bottom: .75rem;

  strong {
    font-weight: 400;
  }
}

h3 {
  font-weight: 400;
  margin-bottom: 0;

  +p, +ol, +ul {
    margin-top: 6px;
  }
}

h4 {
  font-weight: 400;
  margin-bottom: 6px;

  + p {
    margin-top: 0;
  }
}

input::-ms-clear {
  display: none;
}

snack-bar-container[role="alert"] {
  height: auto;

  .mat-simple-snackbar-message {
    white-space: normal;
  }
}

.empty {
  color: mat.get-color-from-palette(mat.$grey-palette, 500);
  font-style: italic;
}

hr {
  margin: 16px 0;
  border: none;
  border-top: dashed 1px #EEE;
}

sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub {
  top: 0.4em;
}

ngu-carousel button.mat-icon-button.carousel-button {
  z-index: 9999;
}

ngu-carousel.banner,
div.banner {
  min-height: 320px;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #FFF;
  text-align: center;

  button.bottom-right {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  .caption {
    width: 100%;
    background: radial-gradient(rgba(0, 0, 0, 0.25), transparent);
    padding: 164px 40px 72px 40px;

    h1 {
      color: #FFF;
      font-size: 42px;
      font-weight: 500;
      letter-spacing: 4px;
    }

    h2 {
      color: #FFF;
      text-align: center;
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 24px;
    }
  }

  .more-info {
    color: #FFF;
    font-size: 12px;
    font-weight: 500;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.3);
    transition: opacity 0.27s;
    letter-spacing: 2px;
    opacity: 1;
    text-align: right;
  }

  a.more-info {
    text-decoration: underline;
    &:hover, &:focus {
      opacity: 0.8;
    }
  }
}

a {
  color: mat.get-color-from-palette(theme.$vni-app-primary, 700);

  &[href="#disclaimer"],
  &[fpScrollTo="#disclaimer"] {
    text-decoration: none;
    color: mat.get-color-from-palette(mat.$grey-palette, 600);
  }
}

.scrollable {
  overflow: auto;
}

button.primary-action {
  position: absolute;
  bottom: 80px;
  right: 40px;
  z-index: 1000;
}

.faq {
  &.answer:before,
  &.question:before {
    content: 'Q.';
    font-size: 18px;
    padding-right: 3px;
  }

  &.answer:before {
    content: 'A.';
  }
}

.product-package {
  max-width: 200px;
  padding: 16px;

  @media only screen
  and (max-width : 599px) {
    max-width: 100px;
  }
}

.checkbox-group {
  margin-bottom: 16px;
}

router-outlet + ng-component {
  height: 100%;
}

mat-form-field.mat-form-field {
  width: 100%;

  .mat-select-min-line {
    line-height: normal;
  }
}

.ngucarousel {
  .slide {
    position: relative;
    background-size: cover;
    background-position: center;
  }

  button.carousel-button {
    color: #FFF;
    margin: auto 6px !important;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    box-shadow: none !important;

    position: absolute;
    top: 0;
    bottom: 0;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }
}

mat-tab-group {
  overflow: hidden;

  .mat-tab-body-wrapper {
    height: 100%;
  }
}

.error {
  color: mat.get-color-from-palette(mat.$red-palette, 700);
}

.warning {
  color: mat.get-color-from-palette(mat.$orange-palette, 700);
}

.warning-alert {
  background-color: mat.get-color-from-palette(mat.$orange-palette, 500);
  border-radius: 4px;
  color: #FFF;
  padding: 16px;
  font-weight: 400;
  font-size: 14px;
}

.text-right {
  text-align: right;
}

.vni-picker {
  margin-bottom: 16px;

  label {
    color: mat.get-color-from-palette(mat.$grey-palette, 700);
    margin-bottom: 0;
    font-size: 12px;
  }

  .value {
    cursor: pointer;
    padding: 6px 0;
  }

  button.mat-icon-button[mat-icon-button] {
    height: 24px;
    width: 24px;
    line-height: 24px;
    font-size: 16px;
  }

  .value-container {
    border-bottom: solid 1px mat.get-color-from-palette(mat.$grey-palette, 700);
  }
}

mat-icon.sm {
  font-size: 18px;
  height: 18px;
  width: 18px;
}

.history-dialog {
  min-width: 300px;
  width: 600px;

  .mat-dialog-content {
    min-height: 200px;
    font-size: 14px;
  }
}

.product-header {
  color: #FFF;
  background-color: #ff4081;
  padding: 6px 64px 0 64px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 100% 100% 0 0;
  letter-spacing: 6px;
}

.dark {
  color: theme.$vni-dark-text-color;
  background-color: mat.get-color-from-palette(theme.$vni-app-primary, 800);

  h1, h2, h3,
  a, input, button.mat-button,
  .mat-menu-item mat-icon {
    color: theme.$vni-dark-text-color;
  }
}

fp-expansion-panel {
  color: rgba(0, 0, 0, 0.87);
}

.warning-notification {
  font-size: 12px;
  color: #707070;
}

.address-street {
  white-space: pre-line;
}

.fade-in {
  opacity: 0;
  transition: opacity 1s;

  &.ng-lazyloaded {
    opacity: 1;
  }
}

.return-value {
  color: mat.get-color-from-palette(mat.$red-palette, 500);
  font-style: italic;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.site-subtitle {
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  letter-spacing: 1px;
  border-top: solid 1px;
  padding-top: 3px;
}

.mat-menu-panel.header {
  background-color: #305687;

  .mat-menu-item {
    color: #FFF;
  }
}

.product-video {
  display: flex;
  position: relative;
  width: 120px;
  height: 66px;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .video-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    transition: all .280s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  mat-icon {
    color: #FFF;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 25%);
    transform: scale(1.2);
    pointer-events: none;
  }

  &:hover, &:focus {
    text-decoration: none !important;
  }
}

@media (min-width: 564px) {
  .angled-ticks {
    .x.axis .tick text {
      text-anchor: end;
      transform: rotate(-45deg);
    }
  }
}

.backoffice-view {
  background-image: url('^assets/img/vni-watermark-color.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  mat-sidenav-container {
    background: transparent;
  }
}

#olark-wrapper {
  #olark-container * {
    font-family: mundial, sans-serif !important;
    font-weight: 300 !important;
    font-display: swap;
  }

  div.olark-launch-button-wrapper,
  div.olark-launch-button-wrapper.olark-text-button {
    right: 40px !important;
    bottom: 0 !important;

    &:focus-within {
      right: 38px !important;
      bottom: 0 !important;
      padding: 0 !important;
    }

    * {
      font-family: mundial, sans-serif !important;
      font-weight: 300 !important;
    }

    .olark-button-focus-wrapper:focus-within {
      padding-bottom: 0 !important;
    }
  }

  #olark-container .olark-branding-link {
    opacity: 0 !important;
    height: 8px !important;
  }
}


li {
  line-height: 1.3;

  &:not(last-child) {
    margin-bottom: 6px;
  }
}

ngx-mat-calendar {
  .mat-calendar-body-cell {
    position: relative !important;
  }
}
mat-calendar {
  .mat-calendar-arrow {
    width: 0;
    height: 0;
  }

  .mat-calendar-body-cell {
    position: absolute !important;
    height: 100%;
  }
}

ngx-mat-datetime-content {
  .mat-calendar-arrow {
    width: 0;
  }
}

.profile-dialog {
  mat-dialog-container.mat-dialog-container {
    padding-top: 0;
  }
}

.header-submenu {
  &.mat-menu-panel {
    min-height: 0;
    margin-top: 12px;
  }

  .mat-menu-content {

    &:not(:empty) {
      padding: 0;
    }

    button.mat-menu-item {
      font-size: 10px;
      font-weight: 600;
      text-align: left;
      height: 32px;
      line-height: 32px;
    }
  }
}

mat-expansion-panel-header > .mat-content {
  overflow: visible;
}

mat-panel-title.mat-expansion-panel-header-title {
  line-height: 1;
}

.mat-expansion-panel-body {
  line-height: 1.8;
}
