@use '@angular/material' as mat;
@use 'theme' as theme;

mat-card.mat-card {
  color: mat.get-color-from-palette(theme.$vni-app-primary, 700);
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  flex-shrink: 0;
  overflow: hidden;

  mat-card-header {
    flex-shrink: 0;
  }

  *:not(mat-icon) {
    font-family: mundial, sans-serif;
  }

  mat-card-content {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .card-banner {
    overflow: hidden;
    margin: -16px -16px 16px -16px;
    min-height: 260px;

    @media only screen
    and (max-width : 599px) {
      margin: -24px -16px 16px -16px;
    }

    span {
      display: block;
      background-size: cover;
      background-position: center;
      height: 260px;
    }

    img {
      width: 100%;
      margin: 0 0 -4px;
    }

    a {
      display: block;

      span {
        text-align: center;
        transition: all .280s ease;
        position: relative;

        mat-icon.video {
          font-size: 80px;
          width: 80px;
          height: 80px;
          color: #FFF;
          opacity: 0.75;

          position: absolute;
          left: 10px;
          top: 10px;

          transition: all .280s ease;
        }

        &:hover {
          transform: scale(1.1);

          > img {
            transform: scale(1.1);
            padding-left: 30%;
          }

          mat-icon.video {
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%) scale(1.3);
          }
        }

        > img {
          max-height: 100%;
          width: auto;
          padding: 24px 24px 24px 50%;
          box-sizing: border-box;

          transition: all .280s ease;
        }
      }
    }
  }

  mat-card-content {
    line-height: 1.5;
  }
}

mat-card-header.mat-card-header {
  h2 {
    margin: 0;
  }

  .mat-card-header-text {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  mat-card-title.mat-card-title {
    font-size: 20px;
    font-weight: 100;
    margin-top: 4px;
    display: inline-block;


    a {
      color: mat.get-color-from-palette(theme.$vni-app-primary, 700);
      text-decoration: none;
      display: inline;
      border-bottom: solid 1px transparent;

      &:hover, &:focus {
        border-bottom: solid 1px mat.get-color-from-palette(theme.$vni-app-primary, 700);
      }
    }
  }

  mat-card-subtitle {
    white-space: normal;
  }
}
