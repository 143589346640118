@use 'sass:map' as map;
@use '@angular/material' as mat;
@use '@angular/material/theming' as theming;

$vni-primary-text-color: #333333;
$vni-contrast-text-color: #F2F2F2;
$vni-accent-text-color: #CBDB2A;
$vni-dark-text-color: #EDEDED;
$vni-link-color: #50AFD5;
$vni-block-content-color: #92E8FB;
$vni-dark-background-color: #333333;
$vni-button-text-color: #6A418A;

$vni-primary: (
  50: #333333,
  100: #333333,
  200: #333333,
  300: #333333,
  400: #333333,
  500: #333333,
  600: #333333,
  700: #333333,
  800: #333333,
  900: #333333,
  A100: #333333,
  A200: #333333,
  A400: #333333,
  A700: #333333,
  contrast: (
      50: #CBDB2A,
      100: #CBDB2A,
      200: #CBDB2A,
      300: #CBDB2A,
      400: #CBDB2A,
      500: #CBDB2A,
      600: #CBDB2A,
      700: #CBDB2A,
      800: #CBDB2A,
      900: #CBDB2A,
      A100: #CBDB2A,
      A200: #CBDB2A,
      A400: #CBDB2A,
      A700: #CBDB2A
  )
);

$vni-accent: (
  50: #CBDB2A,
  100: #CBDB2A,
  200: #CBDB2A,
  300: #CBDB2A,
  400: #CBDB2A,
  500: #CBDB2A,
  600: #CBDB2A,
  700: #CBDB2A,
  800: #CBDB2A,
  900: #CBDB2A,
  A100: #CBDB2A,
  A200: #CBDB2A,
  A400: #CBDB2A,
  A700: #CBDB2A,

  contrast: (
      50: #333333,
      100: #333333,
      200: #333333,
      300: #333333,
      400: #333333,
      500: #333333,
      600: #333333,
      700: #333333,
      800: #333333,
      900: #333333,
      A100: #333333,
      A200: #333333,
      A400: #333333,
      A700: #333333
  )
);

$vni-app-primary: mat.define-palette($vni-primary, 700, A100, A400);
$vni-app-accent: mat.define-palette($vni-accent, A400, A100, A400);
$vni-app-warn: mat.define-palette(mat.$red-palette);
$vni-app-theme: mat.define-light-theme($vni-app-primary, $vni-app-accent, $vni-app-warn);

$vni-typography: mat.define-typography-config(
  $font-family: 'mundial, sans-serif'
);

@include mat.core($vni-typography);
