@use '@angular/material' as mat;

.mat-dialog-actions[mat-dialog-actions] {
  position: relative;
  flex-direction: row;
  justify-content: flex-end;

  mat-spinner {
    position: absolute;
    bottom: 16px;
    left: -16px;
    height: 24px;
  }
}

button.mat-button {
  color: mat.get-color-from-palette(mat.$blue-palette, 700);
}

.radio-container {
  margin: 6px 0 16px 0;

  label.radio-group-label {
    color: mat.get-color-from-palette(mat.$grey-palette, 500);
  }
}

.dialog-title-wrapper {
  position: relative;

  .close-button {
    position: absolute;
    top: -16px;
    right: -16px;
  }
}

.lead-registration-dialog {
  mat-dialog-container.mat-dialog-container {
    padding: 0;

    .mat-dialog-content {
      margin: 0;
      padding: 0;
      max-height: 90vh;
    }

    .mat-dialog-actions {
      margin: 0;
      padding: 0;
    }
  }
}

.dialog-600px {
  .mat-dialog-content {
    padding-bottom: 6px;
  }

  mat-dialog-container.mat-dialog-container {
    width: 600px;
    max-width: 100%;
  }
}

.stepper-container {
  width: 98vw;
  height: 98vh;
  max-width: 680px !important;

  mat-dialog-container.mat-dialog-container {
    padding-bottom: 0;
  }

  .mat-dialog-content {
    max-height: none;
  }
}

.checkout-container {
  width: 98vw;
  height: 98vh;
  max-width: 1200px !important;

  mat-dialog-container.mat-dialog-container {
    padding-bottom: 0;
  }

  .mat-dialog-content {
    max-height: none;
  }
}

.dialog-borderless {
  .mat-dialog-content {
    padding: 0;
    border-radius: 4px;
    max-height: 90vh;
  }

  mat-dialog-container.mat-dialog-container {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    overflow: visible;
  }

  .close-button {
    position: absolute;
    top: -14px;
    right: -12px;
  }
}
